import classic from 'ember-classic-decorator';
import ListController from 'weldnote/controllers/_base/list-js';
import { service } from '@ember/service';

@classic
export default class MiscellaneousCertificateTypesController extends ListController {
  modelName = 'miscellaneous-certificate-type';

  @service
  userSession;
}
