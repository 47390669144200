import { service } from '@ember/service';
import ListController from 'weldnote/controllers/_base/list-js';
import { task } from 'ember-concurrency';

export default class TestStageNamesController extends ListController {
  modelName = 'test-stage-name';

  @service('weldnote-data')
  data;

  @service()
  userSession;

  get totalCount() {
    return this.model.meta.records;
  }

  loadData = task(
    {
      restartable: true,
    },
    async (page = 1) => {
      let options = {
        page,
      };

      let result = await this.data.listAllTestStageNames(options);
      this.model = result;
    }
  );

  get gridColumns() {
    let nameLabel = this.getAttributeLabel('name');
    let orderLabel = this.getAttributeLabel('display-order');

    return [
      {
        valuePath: 'name',
        label: nameLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/test-stage-name-link',
      },
      {
        valuePath: 'displayOrder',
        label: orderLabel,
        sortable: false,
      },
    ];
  }
}
